:root {
  --font-family: "Encode Sans Expanded", sans-serif;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;

  --font-size-regular: 14px;
  --font-size-h5: 16px;

  --primary-color: #146dbe;
  --color-error: #ff4d4f;
  --color-neutral-darkest: #000000;
  --color-neutral-dark: #333333;
  --color-neutral-medium: #cbcbcb;
  --color-neutral-light: #f8fafc;
  --color-neutral-lightest: #ffffff;

  --control-height: 32px;
  --border-radius: 6px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-optical-sizing: auto;
  height: 100%;
}

.map-container {
  height: 100dvh;
  width: 100%;
}

.card {
  position: relative;
  float: left;
  z-index: 1000;
  margin: 8px;
  padding: 8px;
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular);
  background-color: var(--color-neutral-lightest);
  border: 1px solid var(--color-neutral-medium);
  border-radius: var(--border-radius);
  color: var(--color-neutral-darkest);
}

.searchBar {
  display: flex;
  flex-direction: column;
}
.searchBar .error {
  color: var(--color-error);
  text-align: center;
  margin: 8px 0;
}
.searchBar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchBar .container img {
  height: var(--control-height);
}
.searchBar .container input {
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  height: var(--control-height);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-neutral-medium);
  padding: 0px 8px;
  margin: 0 8px;
  width: 75%;
}
.searchBar .container input:focus,
.searchBar .container input:hover {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}
.searchBar .container button {
  cursor: pointer;
  padding: 0px 8px;
  height: var(--control-height);
  background-color: var(--primary-color);
  color: var(--color-neutral-lightest);
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
}

.filters {
  margin: 8px 0;
  overflow: auto;
  transition: all 0.5s;
  text-transform: capitalize;
}
.filters ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.filters .list-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h5);
  margin-top: 8px;
}
.filters .category {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-h5);
  margin: 8px 0 4px;
}
.filters .type {
  color: var(--color-neutral-dark);
  padding: 4px 0;
}
.filters .type label {
  cursor: pointer;
}
.filters-button {
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-neutral-lightest);
  border: 1px solid var(--color-neutral-medium);
  position: absolute;
  bottom: calc(var(--control-height) * -1);
  left: 50%;
  transform: translate(-50%, -50%);
}

.marker-popup {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-regular);
}
.marker-popup-subtitle {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--color-neutral-dark);
  text-transform: capitalize;
  margin-bottom: 4px;
}
